@import url(https://s3.amazonaws.com/icomoon.io/109548/ParkHolidays/style.css?bd0ll0);
@import url(https://s3.amazonaws.com/icomoon.io/109548/Owners-Area/style.css?ni4gu);
/** ICON CLASSES TO GO HERE */
.offcanvas-open {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

